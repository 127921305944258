import React from 'react';
import { Checkbox } from '@clarke-energia/foton';
import { useFormContext } from 'react-hook-form';

import { IBusinessHours, IBusinessHoursSet } from '@contexts/types';
import { ILowTensionSimulatorInputs } from '@contexts/low-tension/types';

import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';
import { fieldVerificator } from '@components/molecules/form/simulator/helper';

import { isStringEmpty, removeNonNumericFromString } from '@utils/common';

import { TimeFormField } from '../fields/time-form-field';
import { RadioFormField } from '../fields/radio-form-field';
import { IGeneralFormSelectableInputsOptions } from '../types';

interface ISimulatorUnitBusinessHoursSubsection extends ISimulatorFormUnitSection {
  selectedTariff?: number;
}

const businessHoursLimits: Array<keyof IBusinessHours> = ['openingTime', 'closingTime'];

interface IBusinessHoursRowProps {
  weekPeriod: keyof IBusinessHoursSet;
  unitNum: number;
  worksOnWeekends: boolean;
}

export function SimulatorUnitBusinessHoursSubsection({
  unitNum,
  fieldsController,
}: ISimulatorUnitBusinessHoursSubsection) {
  const weekPeriods: Array<keyof IBusinessHoursSet> = ['businessDays', 'saturday', 'sunday'];
  const [isInputActive, setIsInputActive] = React.useState<boolean>(false);
  const [worksOnWeekends, setWorksOnWeekends] = React.useState<boolean>(false);
  const defaultBooleanInputOptions: Array<IGeneralFormSelectableInputsOptions> = [
    {
      id: 'true',
      value: 'true',
      optionLabel: 'Sim',
      defaultChecked: isInputActive,
    },
    {
      id: 'false',
      value: 'false',
      optionLabel: 'Não',
      defaultChecked: !isInputActive,
    },
  ];

  const handleWeekendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedChoosedOption = event.target.value === 'true';
    setIsInputActive(parsedChoosedOption);
    setWorksOnWeekends(parsedChoosedOption);
  };

  return (
    <div>
      {fieldVerificator({ fieldName: 'businessHours', fieldPriority: 'SECONDARY', fields: fieldsController }) && (
        <div className="flex flex-col w-full">
          <p className="text-paragraph-large font-semibold mb-4">Horários de Funcionamento</p>
          <div className="mt-3 bg-neutral-10 rounded-medium pt-4 pb-6 px-6">
            <RadioFormField
              label={'Funciona aos finais de semana?'}
              field={''}
              inputOptions={defaultBooleanInputOptions}
              options={{ onChange: handleWeekendChange }}
            />
          </div>
          <div className="flex flex-col gap-5 w-full">
            {weekPeriods.map((weekPeriod) => (
              <BusinessHours
                key={`business-hours-week-period-${weekPeriod}-unit-${unitNum}`}
                unitNum={unitNum}
                weekPeriod={weekPeriod}
                worksOnWeekends={weekPeriod !== 'businessDays' && worksOnWeekends}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const BusinessHours: React.FC<IBusinessHoursRowProps> = ({
  unitNum,
  weekPeriod,
  worksOnWeekends,
}: IBusinessHoursRowProps) => {
  const isBusinessDaysPeriod = weekPeriod === 'businessDays';

  const [isPeriodActive, setIsPeriodActive] = React.useState<boolean>(worksOnWeekends);
  const { setValue } = useFormContext<ILowTensionSimulatorInputs>();
  const [isChecked, setIsChecked] = React.useState<boolean>(
    isBusinessDaysPeriod || (worksOnWeekends && isPeriodActive) ? true : false,
  );

  React.useEffect(() => {
    if (!worksOnWeekends && weekPeriod !== 'businessDays') {
      setIsChecked(false);
      setValue(`units.${unitNum}.businessHours.${weekPeriod}`, null);
    }
  }, [worksOnWeekends, weekPeriod, unitNum]);

  const handleChangePeriodActivation = (isChecked: boolean) => {
    setIsPeriodActive(isChecked);
    setIsChecked(isChecked);
  };

  const formatTime = (value: string) => {
    const numericStringValue = removeNonNumericFromString(value);
    if (numericStringValue.length < 2) return numericStringValue;
    else return `${numericStringValue.slice(0, 2)}:${numericStringValue.slice(2, 4)}h`;
  };

  return (
    <div className="flex gap-8 w-full items-center rounded-2xl bg-neutral-10 py-5 px-7">
      <div className="flex gap-3">
        <Checkbox
          id={`unit-${unitNum}-${weekPeriod}-period-checkbox`}
          label={isBusinessDaysPeriod ? 'Dia Útil' : weekPeriod === 'saturday' ? 'Sábado' : 'Domingo'}
          checked={isChecked}
          onChange={(e) => handleChangePeriodActivation(e.target.checked)}
          disabled={isBusinessDaysPeriod || !worksOnWeekends ? true : false}
        />
      </div>
      {businessHoursLimits.map((businessHour) => {
        const fieldName =
          `units.${unitNum}.businessHours.${weekPeriod}.${businessHour}` as keyof ILowTensionSimulatorInputs;
        return (
          <TimeFormField<ILowTensionSimulatorInputs>
            key={`unit-${unitNum}-${fieldName}`}
            id={`unit-${unitNum}-${fieldName}`}
            field={fieldName}
            label={businessHour === 'openingTime' ? 'Abertura' : 'Fechamento'}
            formatProps={{
              format: formatTime,
              allowNegative: false,
              fixedDecimalScale: false,
              isNumericString: true,
            }}
            step={30}
            className="w-full"
            options={{
              validate: (value) =>
                isStringEmpty(value) ? true : (value as string).length == 4 || 'Insira um horário válido',
              required:
                isBusinessDaysPeriod || (worksOnWeekends && isPeriodActive)
                  ? true
                  : false && {
                      value: true,
                      message: 'Informe um horário para dias úteis.',
                    },
            }}
            disabled={isBusinessDaysPeriod || (worksOnWeekends && isPeriodActive) ? false : true}
          />
        );
      })}
    </div>
  );
};
