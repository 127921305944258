import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';

import {
  LOW_TENSION_OPTING_INITIAL_EXPENSES,
  LOW_TENSION_STANDARD_INITIAL_EXPENSES,
  DEFAULT_CLARKE_RETAIL_PRICE_MARGIN_PERCENTAGE,
} from '@utils/constants';

import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';
import { BuildManagementSimulatorFields, BuildRetailSimulatorFields } from './helper';

interface ISimulatorUnitGeneralSubsection extends ISimulatorFormUnitSection {
  isLowTension: boolean;
  isRetailSimulator: boolean;
}
export function SimulatorUnitGeneralSubsection({
  unitNum,
  fieldsController,
  isLowTension = false,
  isRetailSimulator,
}: ISimulatorUnitGeneralSubsection) {
  const { watch, setValue, trigger } = useFormContext();
  const { user } = useAuth();
  const tariff = watch(`units.${unitNum}.tariff`);

  useEffect(() => {
    if (tariff === 6 || tariff === 7) {
      let initialExpensesValue = null;
      if (tariff === 7) {
        initialExpensesValue = LOW_TENSION_OPTING_INITIAL_EXPENSES;
      } else if (tariff === 6) {
        initialExpensesValue = LOW_TENSION_STANDARD_INITIAL_EXPENSES;
      }
      setValue(`units.${unitNum}.initialExpenses`, initialExpensesValue);
    }
  }, [tariff]);

  const isStateES =
    watch(`units.${unitNum}.energyDistributionCompany`) == '29' ||
    watch(`units.${unitNum}.energyDistributionCompany`) == '30';

  const customFee = watch('customFee');

  const handleChangeIsCachoeiroCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (!customFee) {
        setValue('customFee.type', 'PERCENTAGE');
        setValue('customFee.auxiliarFields.uniqueMargin', DEFAULT_CLARKE_RETAIL_PRICE_MARGIN_PERCENTAGE);
      }
    }
  };

  return !isRetailSimulator ? (
    <BuildManagementSimulatorFields
      {...{
        unitNum,
        fieldsController,
        handleChangeIsCachoeiroCustomer,
        isStateES,
        trigger,
        user,
      }}
    />
  ) : (
    <BuildRetailSimulatorFields
      {...{
        unitNum,
        fieldsController,
        handleChangeIsCachoeiroCustomer,
        isStateES,
        trigger,
        user,
        isLowTension,
      }}
    />
  );
}
