import dayjs from 'dayjs';

import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

import {
  GuaranteeTypeEnum,
  ISimulationCompetitorPayloadBase,
  ISimulationPayloadBase,
  ISimulationUnitPayloadBase,
  ManagementTerminationFineEnum,
  ManagerTypeEnum,
  SimulatorTypeEnum,
  ChargesCoverageTypeEnum,
} from '@contexts/types';
import { addWorkdays, startDate } from '@utils/datetime';

import { DEFAULT_MANAGEMENT_PRICE } from '../../../../utils/constants';

const simulatorsIndexer: Record<keyof typeof SimulatorTypeEnum, Record<string, any> | undefined> = {
  CLARKE_MANAGEMENT: {
    managementPrice: 1000,
    targetYears: [3],
  },
  CLARKE_RETAIL: {
    productType: 'ACL_RETAILER',
    targetYear: 3,
    exclusiveTrader: true,
  },
  COMPARISON_BILL: undefined,
  FEASIBILITY_ANALYSIS: undefined,
  CONTRACTED_DEMAND: undefined,
  LOW_TENSION: {
    productType: 'ACL_RETAILER',
  },
  WHITE_LABEL: {
    managementPrice: 1000,
    targetYears: [3],
  },
};

const getDefaultProposalValidity = (simulator?: keyof typeof SimulatorTypeEnum) => {
  const currentDate = dayjs();
  const daysDiff = simulator === 'CLARKE_MANAGEMENT' ? 1 : 15;

  let resultValidity = currentDate.add(daysDiff, 'days');

  if (resultValidity.isoWeekday() === 6) resultValidity = resultValidity.add(2, 'days');
  else if (resultValidity.isoWeekday() === 7) resultValidity = resultValidity.add(1, 'days');

  return resultValidity.format('DD/MM/YYYY');
};

export const compareAndGetProposalValidity = (
  proposalValidity?: string,
  simulator?: keyof typeof SimulatorTypeEnum,
) => {
  const defaultProposalValidity = getDefaultProposalValidity();

  if (simulator && simulator === 'CLARKE_MANAGEMENT') return addWorkdays(startDate(), 1).format('DD/MM/YYYY');
  if (!proposalValidity) return defaultProposalValidity;

  const currentDate = dayjs();
  const proposalValidityDate = dayjs(proposalValidity, 'DD/MM/YYYY');
  const dateDiff = proposalValidityDate.diff(currentDate, 'day');

  if (dateDiff >= 0) return proposalValidity;
  else return defaultProposalValidity;
};

export const calculateUnitMigrationDate = (onlyReturnYear?: boolean) => {
  const currentDate = dayjs();
  const calculatedMigrationDate = currentDate.add(180, 'days').startOf('month');
  const minimumMigrationDate = dayjs('01/01/2025', 'DD/MM/YYYY');

  const finalMigrationDate = calculatedMigrationDate.isBefore(minimumMigrationDate)
    ? minimumMigrationDate
    : calculatedMigrationDate;

  if (onlyReturnYear) return finalMigrationDate.format('YYYY');
  else return finalMigrationDate.format('DD/MM/YYYY');
};

export const getSimulatorUnitDefaultValues = (
  simulatorType?: keyof typeof SimulatorTypeEnum,
): ISimulationUnitPayloadBase => {
  const managementPrice =
    (simulatorType && simulatorsIndexer[simulatorType]?.managementPrice) ?? DEFAULT_MANAGEMENT_PRICE;

  return {
    consumption: {},
    consumptionOffPeak: {},
    consumptionPeak: {},
    migrationDate: calculateUnitMigrationDate(),
    powerGeneratorCost: 0,
    tariff: 2,
    managementPrice,
    initialExpenses: 0,
  };
};

export const getSimulatorCompetitorDefaultValues = (
  simulatorType?: string,
  isClarke?: boolean,
): ISimulationCompetitorPayloadBase => {
  const flexibilityInitialValue = simulatorType === 'CLARKE_RETAIL' ? 100 : undefined;
  const chargesCoverageTypeInitialValue: keyof typeof ChargesCoverageTypeEnum | undefined =
    simulatorType === 'CLARKE_RETAIL' ? 'FULL_COVERAGE_FOR_ENERGY_CONTRACT' : undefined;
  const managementTerminationFineInitialValue: keyof typeof ManagementTerminationFineEnum | undefined = isClarke
    ? 'THREE_MONTHS_NOTICE'
    : undefined;
  const managerTypeInitialValue: keyof typeof ManagerTypeEnum = isClarke ? 'INDEPENDENT_MANAGER' : 'SALES_MANAGER';
  const guaranteeTypeInitialValue: keyof typeof GuaranteeTypeEnum | undefined = isClarke ? 'NO_GUARANTEE' : undefined;

  return {
    upperFlexibility: flexibilityInitialValue,
    lowerFlexibility: flexibilityInitialValue,
    chargesCoverageType: chargesCoverageTypeInitialValue,
    managementTerminationFine: managementTerminationFineInitialValue,
    managerType: managerTypeInitialValue,
    guaranteeType: guaranteeTypeInitialValue,
  };
};

export const getSimulatorInitialValues = (simulatorType: keyof typeof SimulatorTypeEnum): ISimulationPayloadBase => {
  const simulatorIndexedInitialValues = simulatorsIndexer[simulatorType];
  return {
    aclType: 'FIXED_PRICE',
    calculationType: 'month_to_month',
    hasManualDiscount: false,
    hasManualPrice: false,
    proposalValidity: getDefaultProposalValidity(simulatorType),
    units: [getSimulatorUnitDefaultValues(simulatorType)],
    ...simulatorIndexedInitialValues,
  };
};
