import { useEffect, useState } from 'react';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';

import { ButtonIcon } from '@clarke-energia/foton';

import { ISimulationPayloadBase } from '@contexts/types';

import { SimulatorUnitGeneralSubsection } from '@components/atoms/form/simulation-units-section/simulator-unit-general-subsection';
import { SimulatorUnitDemandSubsection } from '@components/atoms/form/simulation-units-section/simulator-unit-demand-subsection';
import { SimulatorUnitConsumptionData } from '@components/atoms/form/simulation-units-section/simulator-unit-consumption-subsection';
import { SimulatorUnitBusinessHoursSubsection } from '@components/atoms/form/simulation-units-section/simulator-unit-business-hours-subsection';
import { SimulatorUnitBudgetSubsection } from '@components/atoms/form/simulation-units-section/simulator-unit-budget-subsection';
import NewAccordion, { DynamicTypeEnum } from '@components/atoms/accordion';

export interface ISimulatorFormUnitSection {
  children?: JSX.Element;
  fieldsController?: Record<string, boolean>;
  removeUnit?: UseFieldArrayRemove;
  unitary?: boolean;
  unitNum: number;
  unitsLength?: number;
  unifiedConsumption?: boolean;
}

interface IRemoveUnitButton {
  unitNum: number;
  removeUnit?: UseFieldArrayRemove;
}

function RemoveUnitButton({ removeUnit, unitNum }: IRemoveUnitButton) {
  return <ButtonIcon kind="ghost" icon="TrashIcon" onClick={() => removeUnit && removeUnit(unitNum)} />;
}

function SimulatorFormUnitSection({
  fieldsController,
  removeUnit,
  unitary = false,
  unitNum,
  unitsLength,
}: ISimulatorFormUnitSection) {
  const isRetailSimulatorPath = window.location.pathname.includes('clarke-varejo');

  const { watch } = useFormContext<ISimulationPayloadBase>();

  const unitNickname = watch(`units.${unitNum}.nickname`);
  const selectedTariff = watch(`units.${unitNum}.tariff`);

  const unitDescription = `Unidade ${unitNum + 1} ${unitNickname ? `- ${unitNickname}` : ''}`;

  const isFirstUnit = unitNum == 0;
  const isUniqueUnit = unitsLength && unitsLength === 1;
  const [isLowTension, setIsLowTension] = useState<boolean>(false);
  useEffect(() => {
    if (selectedTariff === 6 || selectedTariff === 7) setIsLowTension(true);
    else setIsLowTension(false);
  }, [selectedTariff]);
  return (
    <div className="flex flex-col gap-10 pt-5">
      <NewAccordion
        key={`simulator-form-units-section-accordion-${unitNum}`}
        initiallyOpen={isFirstUnit}
        descriptionElement={
          !isUniqueUnit && removeUnit && <RemoveUnitButton removeUnit={removeUnit} unitNum={unitNum} />
        }
        label={unitDescription}
        dynamicType={DynamicTypeEnum.button}
      >
        <div className="border rounded-small border-neutral-60 p-6 lg:p-8 flex flex-col gap-y-8">
          <SimulatorUnitGeneralSubsection
            isRetailSimulator={isRetailSimulatorPath}
            isLowTension={isLowTension}
            {...{ unitNum, fieldsController }}
          />
          {!isLowTension && <SimulatorUnitDemandSubsection {...{ unitNum, selectedTariff, fieldsController }} />}
          {isLowTension && <SimulatorUnitBusinessHoursSubsection {...{ unitNum, fieldsController }} />}
          <SimulatorUnitConsumptionData unifiedConsumption={isLowTension} {...{ unitNum, fieldsController }} />
          <SimulatorUnitBudgetSubsection {...{ unitNum, unitNickname, fieldsController, unitary }} />
        </div>
      </NewAccordion>
    </div>
  );
}

export default SimulatorFormUnitSection;
