import { FeedbackNotificationProps } from '@clarke-energia/foton';
import _, { isFinite } from 'lodash';

import {
  COMPARISON_BILL_PATH,
  CONTRACTED_DEMAND_PATH,
  FEASIBILITY_ANALYSIS_PATH,
  LOW_TENSION_PATH,
  CLARKE_MANAGEMENT_PATH,
  CLARKE_RETAIL_PATH,
  HOME_PATH,
  WHITE_LABEL_PATH,
} from '@routers/constants';

import { ISimulationUnitPayloadBase, ProductTypeEnum, SimulatorTypeEnum } from '@contexts/types';

export function formatBR(value: number): string {
  return new Intl.NumberFormat('pt-BR').format(value);
}

export const getCalculatorName = (url: string) => {
  if (url.includes(CLARKE_MANAGEMENT_PATH)) {
    return 'gestao';
  } else if (url.includes(FEASIBILITY_ANALYSIS_PATH)) {
    return 'viabilidade';
  } else if (url.includes(COMPARISON_BILL_PATH)) {
    return 'contas';
  } else if (url.includes(CONTRACTED_DEMAND_PATH)) {
    return 'demanda-contratada';
  } else if (url.includes(CLARKE_RETAIL_PATH)) {
    return 'varejo';
  } else if (url.includes(LOW_TENSION_PATH)) {
    return 'baixa-tensao';
  } else if (url.includes(HOME_PATH)) {
    return 'home page';
  } else {
    return '-';
  }
};

export const isOnSimulatorType = (simulatorType: SimulatorTypeEnum): boolean => {
  type SimulatorTypePaths = {
    [key in SimulatorTypeEnum]: string;
  };

  const simulatorTypePathsIndexer: SimulatorTypePaths = {
    '0': CLARKE_MANAGEMENT_PATH,
    '1': CLARKE_RETAIL_PATH,
    '2': COMPARISON_BILL_PATH,
    '3': CONTRACTED_DEMAND_PATH,
    '4': FEASIBILITY_ANALYSIS_PATH,
    '5': LOW_TENSION_PATH,
    '6': WHITE_LABEL_PATH,
  };

  const currentPath = window.location.pathname;

  return currentPath.includes(simulatorTypePathsIndexer[simulatorType]);
};

export const isObjectEmpty = (object?: Record<any, any> | null) => {
  if (object === undefined || !object) return true;

  const objectValuesArray = Object.values(object);
  if (objectValuesArray?.length === 0) return true;
  else if (objectValuesArray.filter((value) => value !== undefined && value !== null).length === 0) return true;
  else return false;
};

export const isArrayEmpty = (array?: Array<any>) => {
  const filteredArray = array?.filter((item) => item);
  if (filteredArray === undefined || !filteredArray) return true;
  else if (filteredArray.length > 0) return false;
  else return true;
};

export const calculateObjectValuesAverage = (object?: Record<number, number | null>): number | undefined => {
  if (!object) return undefined;

  const objectValuesArray = Object.values(object);
  const validObjectValuesArray = objectValuesArray.filter(
    (number) => number && !Number.isNaN(number) !== null && number !== undefined,
  ) as Array<number>;

  if (validObjectValuesArray.length > 0) {
    const objectValuesSum = _.sum(validObjectValuesArray);
    const objectValuesCount = validObjectValuesArray.length;
    return Math.round(objectValuesSum / objectValuesCount);
  }
};

const transformEntityRecursively = (
  entity: Record<string, any> | Array<any>,
  transformKey: (key: string) => string,
) => {
  const restartRecursionForChildEntity = (childEntity: any) => {
    if (_.isPlainObject(childEntity) || _.isArray(childEntity)) transformEntityRecursively(childEntity, transformKey);
  };

  const transformList = (entityAsList: Array<any>) => {
    entityAsList.forEach((childEntity) => restartRecursionForChildEntity(childEntity));
  };

  const transformObject = (entityAsObject: Record<string, any>) => {
    Object.keys(entityAsObject).forEach((key) => {
      const transformedKey = transformKey(key);

      entityAsObject[transformedKey] = entityAsObject[key];
      if (key !== transformedKey) delete entityAsObject[key];

      restartRecursionForChildEntity(entityAsObject[transformedKey]);
    });
  };

  if (_.isArray(entity)) transformList(entity);
  else if (_.isPlainObject(entity)) transformObject(entity);

  return entity;
};

export const transformObjectKeysToSnakeCase = (object: Record<string, any>) => {
  const objectClone = _.cloneDeep(object);
  return transformEntityRecursively(objectClone, _.snakeCase);
};

export const transformObjectKeysToCamelCase = (object: Record<string, any>) => {
  const objectClone = _.cloneDeep(object);
  return transformEntityRecursively(objectClone, _.camelCase);
};

export const thisOrZero = (value?: number) => value ?? 0;

export const parseStringToBoolean = (string: string) => {
  if (string === 'true') return true;
  else if (string === 'false') return false;
  else return undefined;
};

export const convertBooleanToString = (value: boolean) => {
  return value ? 'true' : 'false';
};

export const isValidEmail = (value: string): boolean => {
  if (isStringEmpty(value)) return true;
  const emailPattern: RegExp = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(value);
};

export const isStringEmpty = (value?: string) => {
  if (!value || value === '') return true;
  else return false;
};
export const getAccumulatedDemand = (units?: Array<ISimulationUnitPayloadBase>): number => {
  return (
    units?.reduce((accumulated, unit) => {
      const unitDemandOffPeakSum = unit.aclContractedDemandOffPeak ?? 0;
      return accumulated + unitDemandOffPeakSum;
    }, 0) ?? 0
  );
};

export const getProductType = (
  units?: Array<ISimulationUnitPayloadBase>,
  productType?: keyof typeof ProductTypeEnum,
): keyof typeof ProductTypeEnum => {
  const accumulatedDemand = getAccumulatedDemand(units);

  if (accumulatedDemand < 500 && productType === 'ACL_WHOLESALER') {
    alert(
      'A modalidade de contratação foi automaticamente alterada para Varejista, pois esse grupo não possui demanda suficiente (>= 500 kW).',
    );
    return 'ACL_RETAILER';
  } else return productType as keyof typeof ProductTypeEnum;
};

export const getProposalManagementFeedbackNotificationContent = (
  onCloseNotification: () => void,
): Record<string, FeedbackNotificationProps> => {
  return {
    DELETE_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Proposta excluída.',
      onCloseNotification,
    },
    DELETE_ALL_PROPOSALS_BY_LEAD_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Todas as propostas do grupo foram excluídas com sucesso.',
      onCloseNotification,
    },
    DELETE_ALL_PROPOSALS_BY_LEAD_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'As propostas não foram excluídas.',
      onCloseNotification,
    },
    DELETE_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Proposta não foi excluída.',
      onCloseNotification,
    },
    GET_PROPOSALS_BY_LEAD_ID_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Não foi possível encontrar as propostas deste grupo comercial.',
      onCloseNotification,
    },
    GET_PROPOSALS_BY_LEAD_ID_LOADING: {
      kind: 'neutral',
      label: 'Aguarde',
      message: 'Obtendo proposta do grupo.',
      onCloseNotification,
    },
  };
};

export const insertSubstringAtIndex = (target: string, substring: string, index: number) => {
  const targetArray = target.split('');
  targetArray.splice(index, 0, substring);

  return targetArray.join('');
};

export const removeNonNumericFromString = (value: string) => {
  return value.replace(/[^0-9]/g, '');
};

export const getGroupManagementPrice = (unitsPayload?: Array<ISimulationUnitPayloadBase>) => {
  return unitsPayload?.reduce((accumulated, unit) => accumulated + unit.managementPrice, 0);
};

export const isPositiveNumber = (value: any) => {
  if (value !== undefined && isFinite(value) && value > 0) return true;
  else return false;
};
