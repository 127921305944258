import { AxiosPromise } from 'axios';

import {
  IContractBase,
  IContractPayloadBase,
  IGeneralTitleAndValuePair,
  IProposalBase,
  IResponseBase,
  ISimulationExtendedResultBase,
  ISimulationPayloadBase,
  ISimulationReportBase,
  ISimulationResultBase,
  IUpdateSellerPayload,
  ICommercialDealerComission,
} from '@contexts/types';

export interface IProductTwoContext {
  proposal: IProductTwoProposal;
  createProductTwoSimulation: (
    simulationData: IProductTwoSimulatorInputs,
  ) => AxiosPromise<IResponseBase<IProductTwoProposal>>;
  getProductTwoProposalById: (proposalId?: string) => AxiosPromise<IResponseBase<IProductTwoProposal>>;
  getProductTwoProposals: (
    leadId?: string,
    sellerId?: string,
  ) => AxiosPromise<IResponseBase<Array<IProductTwoProposal>>>;
  deleteProductTwoProposalsHandler: (leadId: string, proposalsIds: Array<string>) => AxiosPromise<IResponseBase>;
  deleteProductTwoAllProposalsByLeadIdHandler: (leadId: string) => AxiosPromise<IResponseBase>;
  updateSellerOfProductTwoProposal: (updateSellerData: IUpdateSellerPayload) => AxiosPromise<IResponseBase>;
  updateProductTwoProposalCommercialDealerComission: (
    proposalId: string,
    commercialDealerComissionData: ICommercialDealerComission,
  ) => AxiosPromise;
}

export type IProductTwoContractInputs = IContractPayloadBase;

export interface IProductTwoContract extends IContractBase {}

type IProductTwoUnitReport = ISimulationReportBase;

export interface IProductTwoRetailTraderPrice {
  id: string;
  prices: Record<string, number>;
  retailTraderId: string;
  expirationDate: string;
}

export interface IProductTwoSimulatorInputs extends ISimulationPayloadBase {
  targetYear: number;
  validPrices: boolean;
  customTaxes?: Record<string, any>;
  state?: string;
  manualAclPricesHasChargesCoverage?: boolean;
  retailTradersPrices: Array<IProductTwoRetailTraderPrice> | null;
}

export interface IProductTwoProposal extends IProposalBase<IProductTwoSimulatorInputs, IProductTwoSimulationScenarios> {
  status: keyof typeof IProductTwoSimulationStatus;
  targetCalculationsNumber: number;
}

export interface IProductTwoSimulationScenarios {
  default: IProductTwoSimulationScenario;
}

export interface IProductTwoSimulationScenario {
  bestRetailTraderResult: IProductTwoBestSimulationResult;
  retailTradersResult: Array<IProductTwoSimulationResultSummary>;
}

export interface IProductTwoBestSimulationResult {
  calculatorMetadata: IProductTwoSimulationResult;
  createdAt: string;
  id: string;
  inputs: IProductTwoSimulatorInputs;
  pricesExpirationDate?: string;
  retailTraderId: string;
  scenario: string;
  simulationP2ProposalId: string;
}

interface IProductTwoSimulationResult extends ISimulationResultBase {
  payback?: number;
  unitsReports: Array<IProductTwoUnitReport>;
}

export interface IProductTwoSimulationExtendedResultBase extends ISimulationExtendedResultBase {
  validPrices?: boolean;
  premises: Array<IGeneralTitleAndValuePair>;
  retailTraderId: string;
}

export interface IProductTwoSimulationExtendedResult
  extends IProductTwoSimulationExtendedResultBase,
    IProductTwoSimulationResult {
  units: Array<IProductTwoSimulationUnitExtendedResult>;
}

export interface IProductTwoSimulationUnitExtendedResult extends IProductTwoUnitReport, ISimulationExtendedResultBase {
  premises: Array<IGeneralTitleAndValuePair>;
}

export interface IProductTwoSimulationResultSummary {
  retailTraderId: string;
  totalSaving: number;
  totalSavingInPercentage: number;
  pricesExpirationDate?: string;
  score?: number;
}

export interface IProductTwoSimulationResultSummaryWithFixedTraderData extends IProductTwoSimulationResultSummary {
  traderLogo: string;
  cceeDuty: string;
  paymentTerms: string;
  penalty: string;
  IREC: string;
}

export enum IProductTwoSimulationStatus {
  IN_QUEUE = 'Aguardando na fila',
  IN_PROGRESS = 'Em andamento',
  DONE = 'Concluído',
  FAILED = 'Falha',
}

export interface IFreeMarketBenefit {
  illustration: string;
  content: React.ReactNode;
}
