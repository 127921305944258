import { useFieldArray } from 'react-hook-form';

import { Button } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';

import { getCalculatorName } from '@utils/common';
import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';
import { ISimulationPayloadBase } from '@contexts/types';

import { getSimulatorUnitDefaultValues } from '../../default-values-getters';
import SimulatorFormUnitSection from './simulator-form-unit-section';

interface ISimulatorFormUnitsSection<T> {
  injectedEnergy?: boolean;
  fieldsController?: Record<keyof T | string, boolean>;
  unitary?: boolean;
}

function SimulatorFormUnitsSection<T>({ fieldsController, unitary }: ISimulatorFormUnitsSection<T>) {
  const { user } = useAuth();
  const { fields, append, remove } = useFieldArray<ISimulationPayloadBase>({
    name: 'units',
    rules: { minLength: 1, required: true },
  });

  return (
    <div className="my-6">
      <p className="text-heading-medium font-semibold">Unidades</p>
      <div className="my-2">
        {fields.map((field, index) => (
          <SimulatorFormUnitSection
            key={field.id}
            unitNum={index}
            removeUnit={remove}
            fieldsController={fieldsController}
            unitary={unitary}
            unitsLength={fields.length}
          />
        ))}
      </div>
      {!unitary && (
        <div className="relative mt-5">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center gap-8">
            {!unitary && (
              <Button
                kind="secondary"
                label="Adicionar unidade"
                size="small"
                type="button"
                onClick={() => {
                  append(getSimulatorUnitDefaultValues());
                  eventTracker.trackEvent(eventLabels.ADD_UNIT_BUTTON_CLICKED, {
                    product: getCalculatorName(location.href),
                    user_groups: user?.groups ? user?.groups.join(', ') : '',
                    user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
                  });
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SimulatorFormUnitsSection;
